<template>
  <Layout>
    <div class="oocontainer">
      <div class="logo" />
      <h1
        class="title"
        :dir="dir"
      >
        {{ content.oo_title }}
      </h1>
      <div
        class="text noheader"
        :dir="dir"
      >
        {{ content.oo_content }}
      </div>
      <div class="actions">
        <Consent ref="optoutconsent" />
        <ExternalLink
          :url="policyUrl"
          route="/pipl/privacy"
          :text="content.v_pp"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import Consent from '@components/optout/Consent.vue'
import ExternalLink from '@components/ExternalLink.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Opt Out',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Opt Out',
        },
      ],
    }
  },
  components: {
    Consent,
    ExternalLink,
  },
  data() {
    return {
      content: {},
      policyUrl: '',
      dir: 'ltr',
    }
  },
  created() {
    this.$compliance.initCollector('optout')
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
    this.$compliance.OptOut.Consent = this.$refs.optoutconsent
    this.$compliance.privacyUrl().then((pp) => {
      this.policyUrl = pp
    })
    this.dir = this.$compliance.dir
  },
}
</script>

<style lang="scss">
.oocontainer {
  background: #fff;
  height: 100%;
  width: 100%;

  .logo {
    background-image: url('../../assets/images/logo.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 20%;
    max-width: 130px;
    height: 40px;
    margin: 20px auto 0 auto;
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }

  .text {
    color: #4c5d6a;
    font-size: 1em;
    text-align: center;
    padding: 0 30px;
    &.noheader {
      margin: 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    a {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  .actions {
    position: relative;
    width: 100%;
    margin: 80px 0 10px 0;
  }
}
.landscape .oocontainer {
  .title {
    margin: 5vh auto 5vh auto;
    @media screen and (min-width: 768px) {
      font-size: 2.4em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 3.2em;
    }
  }
  .text {
    @media screen and (min-width: 768px) {
      font-size: 1em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.8em;
    }
  }
}
.mythiclegends .oocontainer, .triumphoflegends .oocontainer {
  background: #241720;
  .logo {
    width: 88px;
    background-image: url('../../assets/images/o7neo-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .title {
    color: #f3bf76;
  }
  .text {
    color: #eadacb;
    span {
      color: #eadacb;
    }
    a {
      color: #f3bf76;
    }
  }
  .actions .btn {
    color: #614c49;
    &.green {
      background-image: url('../../assets/images/button-green.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    &.grey {
      background-image: url('../../assets/images/button-sand.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    span {
      color: #614c49;
    }
  }
  .external_link .span {
    color: #f3bf76;
  }
}
.onehelsing .oocontainer {
  background: transparent;
  .logo {
    width: 88px;
    background-image: url('../../assets/images/o7neo-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .title {
    color: #ffffff;
    font-weight: 400;
  }
  .text {
    color: #acb9d2;
    font-weight: 400;
  }
  .optout_consent {
    display: flex;
    position: relative;
    padding: 0;
    transform: unset;
  }
  .actions .btn {
    border-radius: 12px;
    color: #ffffff;
    border: 2px solid #000000;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, 2px 2px 0 #000;
    width: 220px;
    margin-bottom: 12px;
    margin-top: 0;
    @media screen and (min-width: 1024px) {
      border-radius: 16px;
      border: 4px solid #000000;
      box-shadow: 4px 4px #000000;
    }
    &.grey {
      background-image: none;
      background-color: #d3b486;
      margin-right: 24px;
    }
    &.green {
      background-image: none;
      background-color: #95c11f;
      margin-left: 24px;
    }
    & span {
      margin-top: 0;
      font-size: 1.2em;
      @media screen and (min-width: 1024px) {
        font-size: 1.6em;
      }
    }
  }
  .external_link .span {
    color: #d2c5ac;
    text-transform: unset;
  }
}
.swampattack2 .oocontainer {
  background: #f4e4a6;
  .logo {
    width: 88px;
    background-image: url('../../assets/images/o7neo-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .title {
    color: #f8f9fa;
    text-shadow: 1px 2px black;
    -webkit-text-stroke: 1px #000;
  }
  .text {
    color: #54361f;
    span {
      color: #54361f;
    }
    a {
      color: #54361f;
    }
  }
  .actions .btn {
    color: #f8f9fa;
    text-shadow: 1px 2px black;
    -webkit-text-stroke: 1px #000;
    padding-bottom: 5px;
    &.green {
      background-image: url('../../assets/images/sa2_button-green.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    &.grey {
      background-image: url('../../assets/images/sa2_button-blue.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    span {
      color: #f8f9fa;
    }
  }
  .external_link .span {
    color: #54361f;
  }
}
</style>
