<template>
  <div class="optout_consent">
    <Button
      :enable="true"
      color="grey"
      :text="content.yes"
      @clicked="toggleConsent(true)"
    />
    <Button
      :enable="true"
      color="green"
      :text="content.no"
      @clicked="toggleConsent(false)"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import Button from '@components/Button.vue'

export default {
  name: 'Consent',
  components: {
    Button
  },
  props: {
    default: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      content: {},
    }
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
  },
  methods: {
    toggleConsent(consented) {
      this.$compliance.OptOut.setOptout(consented)

      Sentry.addBreadcrumb({
        type: 'user',
        category: 'resolution',
        message: '[OptOut] OK',
        timestamp: new Date(),
      })
      this.$compliance.OptOut.confirm()
    },
  },
}
</script>

<style lang="scss">
.optout_consent {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.landscape .optout_consent {
  position: fixed;
  right: 0;
  top: 50%;
  padding: 45px;
  transform: translateY(-50%);
  width: auto;
  margin: 0;
  display: block;
  justify-content: inherit;
}
</style>
